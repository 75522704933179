var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppHeader'),_c('v-divider'),_c('v-layout',{attrs:{"wrap":"","justify-center":"","fill-height":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg2":"","hidden-md-and-down":""}},[_c('v-navigation-drawer',{staticClass:"fixedSidebar",attrs:{"clipped":"","dark":"","src":require("./../assets/images/sd1.jpg"),"overlay-color":"white","width":"100%","height":"100vh","permanent":"","color":"#005f32"},model:{value:(_vm.sideNav),callback:function ($$v) {_vm.sideNav=$$v},expression:"sideNav"}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","text-left":"","py-2":"","text-uppercase":"","align-self-center":""}},_vm._l((_vm.appMenu),function(main,i){return _c('v-list',{key:i,attrs:{"dense":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","py-2":""}},[(main._id !== '60ed5bd072b4ca543c57424d')?_c('v-list-group',{attrs:{"no-action":"","value":false,"active-class":"activated"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',{staticStyle:{"font-size":"13px","letter-spacing":"2px","color":"black","cursor":"pointer","font-family":"kumbhMedium"}},[_c('span',{staticClass:"text-none",staticStyle:{"color":"#ffffff !important"}},[_vm._v(" "+_vm._s(main.name)+" ")])])]},proxy:true}],null,true)},[_vm._l((main.subMenu),function(sub,i){return [_c('v-flex',{key:i,style:(_vm.$route.path == sub.route
                            ? 'background:rgb(240, 115, 5)'
                            : ''),attrs:{"xs12":"","text-left":"","pl-6":"","py-1":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('router-link',{attrs:{"to":sub.route}},[_c('span',{staticStyle:{"letter-spacing":"3px","cursor":"pointer"},style:(_vm.$route.path == sub.route
                                    ? {
                                        'font-family': 'kumbhEBold',
                                        'font-size': ' 13px',
                                      }
                                    : {
                                        'font-family': 'kumbhMedium',
                                        'font-size': ' 13px',
                                      })},[_c('span',{staticClass:"text-none",style:(_vm.$route.path == sub.route
                                      ? 'color:#005f32'
                                      : 'color: #ffffff')},[_vm._v(" "+_vm._s(sub.name)+" ")])])])],1)],1)],1),_c('v-flex',{key:sub._d,attrs:{"xs12":"","py-1":"","px-4":"","pl-6":""}},[_c('div',{staticStyle:{"border-bottom":"1px solid grey"}})])]})],2):_c('v-list',{attrs:{"dark":"","dense":""}},[_vm._l((_vm.admin),function(item,i){return [_c('v-flex',{key:i,attrs:{"xs12":"","pl-4":"","align-self-center":"","text-left":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('router-link',{attrs:{"to":item.route}},[_c('span',{staticStyle:{"font-size":"13px","color":"black","letter-spacing":"3px","cursor":"pointer"},style:(_vm.$route.path == item.route
                                    ? {
                                        'font-family': 'poppinsbold',
                                        'font-size': ' 16px',
                                      }
                                    : {
                                        'font-family': 'kumbhEBold',
                                        'font-size': ' 13px',
                                      })},[_vm._v(" "+_vm._s(item.name)+" ")])])],1)],1)],1)]})],2)],1)],1)],1)}),1)],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg10":"","pt-12":"","pt-lg-0":""}},[_c('v-layout',{style:(_vm.$route.name == 'Dashboard'
            ? 'background-color: #FFFFFF'
            : 'background-color: #F5F5DC'),attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","pa-6":""}},[_c('v-card',{attrs:{"tile":"","min-height":"100vh","color":_vm.$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF',"flat":_vm.$route.name == 'Dashboard' ? true : false}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","pa-4":""}},[_c('router-view',{key:_vm.$route.fullPath})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }